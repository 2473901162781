import { orderConstants } from '../constants';

export function order(state = {}, action) {
    switch (action.type) {
        case orderConstants.LIST_ORDERS_REQUEST:
            return { ...state, getting: true };
        case orderConstants.LIST_ORDERS_SUCCESS:
            return {
                ...state,
                getting: false,
                ordersList: action.value.orders
            };
        case orderConstants.LIST_ORDERS_FAILURE:
            return state;
        default:
            return state
    }
}