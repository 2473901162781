import { orderConstants } from '../constants';
import { orderService } from '../services';

export const orderActions = {
    list
};

/**
 * Gets the list of orders
 */
function list() {
    return dispatch => {
        dispatch(request());
        orderService.list()
            .then(
                value => {
                    dispatch(success(value));
                },
                error => {
                    console.log(error);
                    dispatch(failure(error));
                }
            );
    };

    function request(value) { return { type: orderConstants.LIST_ORDERS_REQUEST, value } }
    function success(value) { return { type: orderConstants.LIST_ORDERS_SUCCESS, value } }
    function failure(error) { return { type: orderConstants.LIST_ORDERS_FAILURE, error } }
}
