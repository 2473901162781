import { addressesConstants } from '../constants';
import { addressesService } from '../services';
import { alertActions } from './';
import { history } from '../helpers';
import { cartActions } from './cart.actions';

export const addressesActions = {
    add,
    remove,
    get,
    update,
    select
};

/**
 * Adds a address to the cart
 *
 * @param {object} address
 */
function add(address) {
    return dispatch => {
        dispatch(request(address));

        addressesService.add(address)
            .then(
                value => {
                    dispatch(success(value));
                    dispatch(alertActions.success("Dirección añadida"))
                    history.goBack();
                },
                error => {
                    console.log(error);
                    dispatch(failure(error));
                }
            );
    };

    function request(value) { return { type: addressesConstants.ADD_ADDRESS_REQUEST, value } }
    function success(value) { return { type: addressesConstants.ADD_ADDRESS_SUCCESS, value } }
    function failure(error) { return { type: addressesConstants.ADD_ADDRESS_FAILURE, error } }
}

/**
 * Populates local storage addresses to the cart
 *
 * @param {object} address
 */
function update(id, data) {
    return dispatch => {
        dispatch(request([id, data]));

        addressesService.update(id, data)
            .then(
                value => {
                    dispatch(success(value));
                    dispatch(alertActions.success("Dirección modificada"));
                    history.goBack();
                },
                error => {
                    console.log(error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error))
                }
            );
    };

    function request(value) { return { type: addressesConstants.UPDATE_ADDRESS_REQUEST, value } }
    function success(value) { return { type: addressesConstants.UPDATE_ADDRESS_SUCCESS, value } }
    function failure(error) { return { type: addressesConstants.UPDATE_ADDRESS_FAILURE, error } }
}

/**
 * Removes a address from the cart by Id
 *
 * @param {int} addressId
 */
function remove(addressId) {
    return dispatch => {
        dispatch(request(addressId));

        addressesService.remove(addressId)
            .then(
                value => {
                    dispatch(success(value));
                    dispatch(alertActions.success("Dirección borrada"))
                    history.goBack();
                },
                error => {
                    console.log(error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error))
                }
            );
    };

    function request(value) { return { type: addressesConstants.REMOVE_ADDRESS_REQUEST, value } }
    function success(value) { return { type: addressesConstants.REMOVE_ADDRESS_SUCCESS, value } }
    function failure(error) { return { type: addressesConstants.REMOVE_ADDRESS_FAILURE, error } }
}

/**
 * Gets the content of the cart
 */
function get() {
    return dispatch => {
        dispatch(request());

        addressesService.get()
            .then(
                value => {
                    dispatch(success(value));
                },
                error => {
                    console.log(error);
                    dispatch(failure(error));
                }
            );
    };

    function request(value) { return { type: addressesConstants.GET_ADDRESSES_REQUEST, value } }
    function success(value) { return { type: addressesConstants.GET_ADDRESSES_SUCCESS, value } }
    function failure(error) { return { type: addressesConstants.GET_ADDRESSES_FAILURE, error } }
}

function select(idAddress, realId) {
    return dispatch => {
        dispatch(selectAddress(idAddress));
        dispatch(cartActions.getPrice(realId));
    };
    function selectAddress(value) { return { type: addressesConstants.SELECT_ADDRESS, value} }
}