import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import './Footer.css';

class Footer extends Component {
    render() {
        return (
            <footer id="footer" className="footer">
                <div className="links">
                    <div className="socialNetworks">
                        <div className="facebook"><a name="facebookSilverand" target="_blank" rel="noopener noreferrer" href="https://es-es.facebook.com/silverandcomplements/"><svg width="45px" height="45px" className="svg-icon" viewBox="0 0 20 20">
                            <path d="M11.344,5.71c0-0.73,0.074-1.122,1.199-1.122h1.502V1.871h-2.404c-2.886,0-3.903,1.36-3.903,3.646v1.765h-1.8V10h1.8v8.128h3.601V10h2.403l0.32-2.718h-2.724L11.344,5.71z"></path>
                        </svg></a></div>
                        <div className="instagram"><a name="instagramSilverand" target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/silver.and"><svg width="45px" height="45px" className="svg-icon" viewBox="0 0 20 20">
                            <path d="M14.52,2.469H5.482c-1.664,0-3.013,1.349-3.013,3.013v9.038c0,1.662,1.349,3.012,3.013,3.012h9.038c1.662,0,3.012-1.35,3.012-3.012V5.482C17.531,3.818,16.182,2.469,14.52,2.469 M13.012,4.729h2.26v2.259h-2.26V4.729z M10,6.988c1.664,0,3.012,1.349,3.012,3.012c0,1.664-1.348,3.013-3.012,3.013c-1.664,0-3.012-1.349-3.012-3.013C6.988,8.336,8.336,6.988,10,6.988 M16.025,14.52c0,0.831-0.676,1.506-1.506,1.506H5.482c-0.831,0-1.507-0.675-1.507-1.506V9.247h1.583C5.516,9.494,5.482,9.743,5.482,10c0,2.497,2.023,4.52,4.518,4.52c2.494,0,4.52-2.022,4.52-4.52c0-0.257-0.035-0.506-0.076-0.753h1.582V14.52z"></path>
                        </svg></a>
                        </div>
                    </div>
                    <div className="contact">
                        <p>Contacto comercial: <strong>comercial@silverand.es</strong></p>
                        <p>Ayuda soporte técnico: <strong>soporte@silverand.es</strong></p>
                    </div>
                    <div className="tienda">
                        <p>Tienda física: <strong>Centro Comercial El Teler</strong> Calle del Pintor Josep Segrelles, 1, 46870 Ontinyent, Valencia</p>
                        <p>Horario: De lunes a sabado 10:00 a 14:00 y 17:00 a 21:00</p>
                    </div>
                </div>
                <div className="legalInfo">
                    <div className="legal">
                        <div className="cookies">
                            <p>Al entrar en este sitio web aceptas el uso de cookies esenciales para el funcionamiento de la web. Estas cookies no son utilizadas para recolectar información del usuario de ningún tipo. Puedes deshabilitarlas en la configuración de tu navegador pero esto puede afectar al correcto funcionamiento de la página.</p>
                        </div>
                        <div className="copyright">
                            <p>© Silver and... Todos los derechos reservados.</p>
                        </div>
                    </div>
                    <div className="legalLinks">
                        <Link to="/legal-advice">Aviso legal</Link>
                        <Link to="/privacy-policy">Política de privacidad</Link>
                    </div>
                </div>
            </footer>
        )
    }
}

function mapState(state) {
    const { authentication, cart } = state;
    const { loggedIn, user } = authentication;
    const { cartList } = cart;
    return { loggedIn, user, cartList };
}

const actionCreators = {
};

const connectedFooterPage = connect(mapState, actionCreators)(Footer);
export default connectedFooterPage;