export const addressesConstants = {
    ADD_ADDRESS_REQUEST: 'ADD_ADDRESS_REQUEST',
    ADD_ADDRESS_SUCCESS: 'ADD_ADDRESS_SUCCESS',
    ADD_ADDRESS_FAILURE: 'ADD_ADDRESS_FAILURE',
    REMOVE_ADDRESS_REQUEST: 'REMOVE_ADDRESS_REQUEST',
    REMOVE_ADDRESS_SUCCESS: 'REMOVE_ADDRESS_SUCCESS',
    REMOVE_ADDRESS_FAILURE: 'REMOVE_ADDRESS_FAILURE',
    UPDATE_ADDRESS_REQUEST: 'UPDATE_ADDRESS_REQUEST',
    UPDATE_ADDRESS_SUCCESS: 'UPDATE_ADDRESS_SUCCESS',
    UPDATE_ADDRESS_FAILURE: 'UPDATE_ADDRESS_FAILURE',
    GET_ADDRESSES_REQUEST: 'GET_ADDRESSES_REQUEST',
    GET_ADDRESSES_SUCCESS: 'GET_ADDRESSES_SUCCESS',
    GET_ADDRESSES_FAILURE: 'GET_ADDRESSES_FAILURE',
    SELECT_ADDRESS: 'SELECT_ADDRESS',
};