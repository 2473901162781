import React, { Component } from 'react';
import Product from '../Product/Product.js';
import './ProductList.css';
import categories from '../../resources/categories';
import hashCategories from '../../resources/hashCategories';
import hashOrders from '../../resources/hashOrders';
import hashParams from '../../resources/hashParams';
import orders from '../../resources/orders';
import { productActions } from '../../actions';
import { connect } from 'react-redux';
import capitalize from '../../helpers/utils';
import debounce from "lodash.debounce";

class ProductList extends Component {
    constructor(props) {
        super(props);
        let params = { order: (props.order !== undefined) ? props.order : "desc" };
        let type = (props.productUrl !== undefined) ? props.productUrl.toLowerCase() : "todos";
        let orderBy = (props.orderParam !== undefined && props.order !== undefined) ? hashParams[props.orderParam + "-" + props.order] : "novedades";
        let hashCategory = 0;
        let hashOrder = {"param": "id", "id": 0};
        if (props.match !== undefined) {
            params = (props.match.params !== undefined) ? props.match.params : params;
        }
        type = (params.type !== undefined) ? params.type : type;
        orderBy = (params.orderBy !== undefined) ? params.orderBy : orderBy;
        hashCategory = (hashCategories[type] !== undefined) ? hashCategories[type] : 0;
        hashOrder = (hashOrders[orderBy] !== undefined) ? hashOrders[orderBy] : {"param": "id", "id": 0};
        this.state = { //Usage of the state for the form
            productList: (props.productList !== undefined) ? props.productList : [],
            productId: hashCategory,
            productName: capitalize(categories[hashCategory]),
            productUrl: categories[hashCategory].toLowerCase(),
            orderId: hashOrder.id,
            orderParam: hashOrder.param,
            orderName: orders[hashOrder.id].text,
            orderUrl: orders[hashOrder.id].url,
            order: (params.order !== undefined) ? params.order : "desc",
            limit: (props.limit !== undefined) ? props.limit : 20,
            offset: (props.offset !== undefined) ? props.offset : 0
        }
        window.onscroll = debounce(() => {
            const {
                loadProducts,
                getScrollTop,
                getDocumentHeight,
                props: {
                    error,
                    getting,
                    hasMore,
                },
            } = this;
            if (!(error || getting || !hasMore || (getScrollTop() + 1000 < getDocumentHeight() - window.innerHeight))) {
                loadProducts();
            }
        }, 100);
        this.handleProductChange = this.handleProductChange.bind(this);
        this.handleOrderChange = this.handleOrderChange.bind(this);
        if (this.state.productList.length < 1) {
            this.props.list(this.state.productUrl.toLowerCase(), this.state.orderParam, this.state.order, this.state.limit, this.state.offset);
        }
    }
    getScrollTop() {
        return (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
    }
    getDocumentHeight() {
        const body = document.body;
        const html = document.documentElement;

        return Math.max(
            body.scrollHeight, body.offsetHeight,
            html.clientHeight, html.scrollHeight, html.offsetHeight
        );
    }
    loadProducts = () => {
        this.setState({
            ...this.state,
            offset: this.state.offset + 20
        }, () => {
            this.props.list(this.state.productUrl.toLowerCase(), this.state.orderParam, this.state.order, this.state.limit, this.state.offset, true);
        });
    }
    handleProductChange(e) {
        const { value } = e.target;
        this.setState({
            ...this.state,
            productId: value,
            productName: categories[value],
            productUrl: categories[value].toLowerCase(),
            limit: 20,
            offset: 0
        });
        this.props.list(categories[value].toLowerCase(), this.state.orderParam, this.state.order, 20, 0);
    }
    handleOrderChange(e) {
        const { value } = e.target;
        let params = value.split("-");
        this.setState({
            ...this.state,
            orderParam: params[0],
            order: params[1],
            limit: 20,
            offset: 0
        });
        this.props.list(this.state.productUrl.toLowerCase(), params[0], params[1], 20, 0);
    }
    render() {
        return (
            <div id="productList">
                <nav className="product-filter">
                    <div className="empty">
                    </div>
                    <div className="sort">
                        <div className="collection-sort">
                            <label for="productSelect" >Producto:</label>
                            <div className="select">
                                <select id="productSelect" onChange={this.handleProductChange} className="custom-select">
                                    {  categories && categories.map((category, index) =>
                                        <option key={index} selected={this.state.productId === index} value={index}>{category}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className="collection-sort">
                            <label for="orderSelect">Ordenar por:</label>
                            <div className="select">
                                <select id="orderSelect" onChange={this.handleOrderChange} className="custom-select">
                                    {  orders && orders.map((order, index) =>
                                        <option key={index} selected={this.state.orderId === index} value={order.param + '-' + order.order}>{order.text}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>
                </nav>
                <div className="products">
                    { this.props.productList && this.props.productList.map((product, index) => // Only goes through each product on the list if the list isn't undefined
                        <Product key={index} product={product} /> //Use of props
                    )}
                </div>
                { this.props.getting && <div style={{marginTop: "40px"}} className="loading-box">
                    <div className="loading">
                        <svg width="24px" height="24px" className="svg-icon" viewBox="0 0 20 20">
                            <path d="M9.719,17.073l-6.562-6.51c-0.27-0.268-0.504-0.567-0.696-0.888C1.385,7.89,1.67,5.613,3.155,4.14c0.864-0.856,2.012-1.329,3.233-1.329c1.924,0,3.115,1.12,3.612,1.752c0.499-0.634,1.689-1.752,3.612-1.752c1.221,0,2.369,0.472,3.233,1.329c1.484,1.473,1.771,3.75,0.693,5.537c-0.19,0.32-0.425,0.618-0.695,0.887l-6.562,6.51C10.125,17.229,9.875,17.229,9.719,17.073 M6.388,3.61C5.379,3.61,4.431,4,3.717,4.707C2.495,5.92,2.259,7.794,3.145,9.265c0.158,0.265,0.351,0.51,0.574,0.731L10,16.228l6.281-6.232c0.224-0.221,0.416-0.466,0.573-0.729c0.887-1.472,0.651-3.346-0.571-4.56C15.57,4,14.621,3.61,13.612,3.61c-1.43,0-2.639,0.786-3.268,1.863c-0.154,0.264-0.536,0.264-0.69,0C9.029,4.397,7.82,3.61,6.388,3.61"></path>
                        </svg>
                    </div>
                </div>
                }
            </div>
        )
    }
}
function mapState(state) {
    const { alert, product } = state;
    const { list } = product;
    const { productList, error, getting, hasMore, productUrl, orderParam, order, limit, offset } = state.product;
    return { alert, list, productList, error, getting, hasMore, productUrl, orderParam, order, limit, offset };
}

const actionCreators = {
    list: productActions.list
};


const connectedProductList = connect(mapState, actionCreators)(ProductList)
export default connectedProductList;