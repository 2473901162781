import React, {Component} from 'react';

class Nothing extends Component {
    render() {
        return (
            <div style={{textAlign: "center", marginTop: "50px"}}>
                <h2 style={{textAlign: "center", position: "absolute", top:"50%", left:"50%", transform:"translate(-50%, -50%)"}}>404 Página no encontrada</h2>
            </div>
        );
    }
}

export default Nothing;