import { addressesConstants } from '../constants';

export function addresses(state = {}, action) {
    switch (action.type) {
        case addressesConstants.GET_ADDRESS_REQUEST:
            return { state };
        case addressesConstants.ADD_ADDRESS_SUCCESS:
        case addressesConstants.UPDATE_ADDRESS_SUCCESS:
        case addressesConstants.REMOVE_ADDRESS_SUCCESS:
        case addressesConstants.GET_ADDRESSES_SUCCESS:
            return {
                ...state,
                addressesList: action.value.addresses
            };
        case addressesConstants.GET_ADDRESSES_FAILURE:
            return state;
        case addressesConstants.SELECT_ADDRESS:
            return {
                ...state,
                selectedAddress: action.value
            }
      default:
        return state
    }
  }