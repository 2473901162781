import { productConstants } from '../constants';
import { productService } from '../services';

export const productActions = {
    list,
    get
};

/**
 * Gets the list of products
 */
function list(productUrl, orderParam, order, limit, offset, appendList = false) {
    return dispatch => {
        dispatch(request());

        productService.list(productUrl, orderParam, order, limit, offset)
            .then(
                value => {
                    value.params = {
                        productUrl,
                        orderParam,
                        order,
                        limit,
                        offset
                    }
                    if (appendList) {
                        dispatch(append(value));
                    } else {
                        dispatch(success(value));
                    }
                },
                error => {
                    console.log(error);
                    dispatch(failure(error));
                }
            );
    };

    function request(value) { return { type: productConstants.LIST_PRODUCT_REQUEST, value } }
    function success(value) { return { type: productConstants.LIST_PRODUCT_SUCCESS, value } }
    function append(value) { return { type: productConstants.LIST_PRODUCT_APPEND, value } }
    function failure(error) { return { type: productConstants.LIST_PRODUCT_FAILURE, error } }
}

/**
 * Get product by slug
 */
function get(slug) {
    return dispatch => {
        dispatch(request(slug));

        productService.get(slug)
            .then(
                value => {
                    dispatch(success(value));
                },
                error => {
                    console.log(error);
                    dispatch(failure(error));
                }
            );
    };

    function request(value) { return { type: productConstants.GET_PRODUCT_REQUEST, value } }
    function success(value) { return { type: productConstants.GET_PRODUCT_SUCCESS, value } }
    function failure(error) { return { type: productConstants.GET_PRODUCT_FAILURE, error } }
}