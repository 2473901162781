import config from '../helpers/config.json';
import { authHeader } from '../helpers';

export const orderService = {
    list,
};

function list() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.user && user.user.token) {
        const requestOptions = {
            method: "GET",
            headers: authHeader()
        };
        return fetch(`${config.apiUrl}/checkout/orders/`,
            requestOptions).then(handleResponse);
    } else {
        return Promise.reject();
    }
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                localStorage.removeItem('user');
                window.location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}