import React, {Component} from 'react';
import './App.css';
import { Route, Switch } from "react-router-dom";
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Nothing from './components/Nothing/Nothing.js';
import Home from './components/Home/Home.js';
import Cart from './components/Cart/Cart.js';
import Favorites from './components/Favorites/Favorites.js';
import 'react-toastify/dist/ReactToastify.css';
import Checkout from './components/Checkout/Checkout.js';
import ProductList from './components/ProductList/ProductList';
import Login from './components/Login/Login';
import Register from './components/Register/Register';
import ProductPage from './components/ProductPage/ProductPage';
import Account from './components/Account/Account';
import { cartActions, addressesActions } from './actions';
import { connect } from 'react-redux';
import { favoritesActions } from './actions/favorites.actions';
import LegalAdvice from './components/LegalAdvice/LegalAdvice';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import EditAddress from './components/EditAddress/EditAddress';
import CreateAddress from './components/CreateAddress/CreateAddress';
import Orders from './components/Orders/Orders';

/**
 * Main application
 */
class App extends Component {
  constructor(params) {
    super(params);
    this.props.getCart();
    this.props.getFavorites();
    this.props.getAddresses();
  }
  render() {
    return (
      <div>
        <Header />
        <div id="content">
          <Switch>
            <Route exact path="/" component={Home}></Route>
            <Route exact path="/bag/" component={Cart}></Route>
            <Route exact path="/favorites/" component={Favorites}></Route>
            <Route exact path="/legal-advice/" component={LegalAdvice}></Route>
            <Route exact path="/privacy-policy/" component={PrivacyPolicy}></Route>
            <Route exact path="/edit-address/:index" component={EditAddress}></Route>
            <Route exact path="/create-address/" component={CreateAddress}></Route>
            <Route exact path="/checkout/" component={Checkout}></Route>
            <Route exact path="/account/" component={Account}></Route>
            <Route exact path="/products/" component={ProductList}></Route>
            <Route exact path="/orders/" component={Orders}></Route>
            <Route exact path="/login/" component={Login}></Route>
            <Route exact path="/register/" component={Register}></Route>
            <Route exact path="/products/:type/" component={ProductList}></Route>
            <Route exact path="/products/:type/:orderBy/" component={ProductList}></Route>
            <Route exact path="/products/:type/:orderBy/:order/" component={ProductList}></Route>
            <Route exact path="/product/:slug/" render={props => <ProductPage key={Date.now()} {...props} />}></Route>
            <Route component={Nothing} />
          </Switch>
        </div>
        <Footer />
      </div>
    );
  }
}

function mapState(state) {
  const { alert, cart } = state;
  return { alert, cart };
}

const actionCreators = {
  getCart: cartActions.get,
  getFavorites: favoritesActions.get,
  getAddresses: addressesActions.get
};

const connectedProductPage = connect(mapState, actionCreators)(App)
export default connectedProductPage;