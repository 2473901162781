import { cartConstants } from '../constants';
import { cartService } from '../services';
import { alertActions } from './';

export const cartActions = {
    add,
    remove,
    get,
    order,
    populate,
    getPrice
};

/**
 * Adds a product to the cart
 *
 * @param {object} product
 */
function add(product, quantity = 1) {
    return dispatch => {
        dispatch(request([ product, quantity ]));

        cartService.add(product, quantity)
            .then(
                value => {
                    let hashMap = {};
                    value.products.map((value) => {
                        if (value !== null) hashMap[value.id_product_cart] = value;
                        return value;
                    });
                    dispatch(success(hashMap));
                    if (quantity > 0) {
                        dispatch(alertActions.success(product.name + " añadido a la bolsa"))
                    } else {
                        dispatch(alertActions.success(product.name + " quitado de la bolsa"))
                    }
                },
                error => {
                    console.log(error);
                    dispatch(failure(error));
                }
            );
    };

    function request(value) { return { type: cartConstants.ADD_CART_REQUEST, value } }
    function success(value) { return { type: cartConstants.ADD_CART_SUCCESS, value } }
    function failure(error) { return { type: cartConstants.ADD_CART_FAILURE, error } }
}

/**
 * Populates local storage products to the cart
 *
 * @param {object} product
 */
function populate() {
    return dispatch => {
        dispatch(request());

        cartService.populate()
            .then(
                value => {
                    let hashMap = {};
                    value.products.map((value) => {
                        if (value !== null) hashMap[value.id_product_cart] = value;
                        return value;
                    });
                    dispatch(success(hashMap));
                },
                error => {
                    console.log(error);
                    dispatch(failure(error));
                }
            );
    };

    function request(value) { return { type: cartConstants.POPULATE_CART_REQUEST, value } }
    function success(value) { return { type: cartConstants.POPULATE_CART_SUCCESS, value } }
    function failure(error) { return { type: cartConstants.POPULATE_CART_FAILURE, error } }
}

/**
 * Removes a product from the cart by Id
 *
 * @param {int} productId
 */
function remove(productId) {
    return dispatch => {
        dispatch(request(productId));

        cartService.remove(productId)
            .then(
                value => {
                    dispatch(success(value));
                    dispatch(alertActions.success("Product removed"))
                },
                error => {
                    console.log(error);
                    dispatch(failure(error));
                }
            );
    };

    function request(value) { return { type: cartConstants.REMOVE_CART_REQUEST, value } }
    function success(value) { return { type: cartConstants.REMOVE_CART_SUCCESS, value } }
    function failure(error) { return { type: cartConstants.REMOVE_CART_FAILURE, error } }
}

/**
 * Gets the content of the cart
 */
function get() {
    return dispatch => {
        dispatch(request());

        cartService.get()
            .then(
                value => {
                    let hashMap = {};
                    value.products.map((value) => {
                        if (value !== null) hashMap[value.id_product_cart] = value;
                        return value;
                    });
                    dispatch(success(hashMap));
                },
                error => {
                    console.log(error);
                    dispatch(failure(error));
                }
            );
    };

    function request(value) { return { type: cartConstants.GET_CART_REQUEST, value } }
    function success(value) { return { type: cartConstants.GET_CART_SUCCESS, value } }
    function failure(error) { return { type: cartConstants.GET_CART_FAILURE, error } }
}

/**
 * Creates an order with the content of the cart
 *
 * @param {string} name
 * @param {string} address
 * @param {string} cardNumber
 */
function order(name, address, cardNumber) {
    return dispatch => {
        dispatch(request({name, address, cardNumber}));

        cartService.order(name, address, cardNumber)
            .then(
                value => {
                    dispatch(success(value));
                    dispatch(alertActions.success("Products ordered"))
                },
                error => {
                    console.log(error);
                    dispatch(failure(error));
                }
            );
    };

    function request(value) { return { type: cartConstants.ORDER_CART_REQUEST, value } }
    function success(value) { return { type: cartConstants.ORDER_CART_SUCCESS, value } }
    function failure(error) { return { type: cartConstants.ORDER_CART_FAILURE, error } }
}

function getPrice(idAddress) {
    return dispatch => {
        dispatch(request(idAddress));

        cartService.getPrice(idAddress)
            .then(
                value => {
                    dispatch(success(value));
                },
                error => {
                    //console.log(error);
                    dispatch(failure(error));
                }
            );
    };

    function request(value) { return { type: cartConstants.GET_CART_PRICE_REQUEST, value } }
    function success(value) { return { type: cartConstants.GET_CART_PRICE_SUCCESS, value } }
    function failure(error) { return { type: cartConstants.GET_CART_PRICE_FAILURE, error } }
}