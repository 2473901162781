import { cartConstants } from '../constants';

export function cart(state = {}, action) {
    switch (action.type) {
        case cartConstants.GET_CART_REQUEST:
            return { state };
        case cartConstants.ADD_CART_SUCCESS:
        case cartConstants.POPULATE_CART_SUCCESS:
        case cartConstants.GET_CART_SUCCESS:
            return {
                ...state,
                cartList: action.value
            };
        case cartConstants.GET_CART_FAILURE:
            return state;
        case cartConstants.GET_CART_PRICE_SUCCESS:
            return {
                ...state,
                totalPrice: action.value.price,
                stockAvailable: action.value.available,
                transferData: action.value.params,
                signature: action.value.signature
            }
      default:
        return state
    }
  }