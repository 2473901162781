import { userConstants } from '../constants';
import { userService } from '../services';
import { alertActions, cartActions, favoritesActions } from './';
import { addressesActions } from './addresses.actions';

export const userActions = {
    login,
    logout,
    register,
    changePassword
};

function login(email, password) {
    return dispatch => {
        dispatch(request( email ));

        userService.login(email, password)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success("Sesión iniciada"));
                    dispatch(favoritesActions.get());
                    dispatch(addressesActions.get());
                    dispatch(cartActions.populate());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error))
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function logout() {
    userService.logout();
    return dispatch => {
        userService.logout();
        dispatch(success());
        dispatch(alertActions.success("Sesión cerrada"));
        dispatch(favoritesActions.get());
        dispatch(cartActions.get());
        dispatch(addressesActions.get());
    };
    function success() { return { type: userConstants.LOGOUT } }
}

function register(email, password) {
    return dispatch => {
        dispatch(request( email ));

        userService.register(email, password)
            .then(
                user => {
                    dispatch(success(user))
                    dispatch(alertActions.success('Registration successful'));
                    dispatch(favoritesActions.get());
                    dispatch(cartActions.populate());
                    dispatch(addressesActions.get());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error))
                }
            );
    };

    function request(user) { return { type: userConstants.REGISTER_REQUEST, user } }
    function success(user) { return { type: userConstants.REGISTER_SUCCESS, user } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}

function changePassword(oldPassword, newPassword) {
    return dispatch => {
        dispatch(request( oldPassword, newPassword ));
        userService.changePassword(oldPassword, newPassword)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success("Contraseña cambiada"));
                    dispatch(favoritesActions.get());
                    dispatch(addressesActions.get());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error))
                }
            );
    };

    function request(user) { return { type: userConstants.CHANGE_PASSWORD_REQUEST, user } }
    function success(user) { return { type: userConstants.CHANGE_PASSWORD_SUCCESS, user } }
    function failure(error) { return { type: userConstants.CHANGE_PASSWORD_FAILURE, error } }
}