import { favoritesConstants } from '../constants';
import { favoritesService } from '../services';

export const favoritesActions = {
    add,
    get
};

/**
 * Adds a product to the favorites
 *
 * @param {object} product
 */
function add(product) {
    return dispatch => {
        dispatch(request([ product ]));

        favoritesService.add(product)
            .then(
                value => {
                    let hashMap = {};
                    value.products.map((value) => {
                        if (value !== null) hashMap[value.id_product_favorites] = value;
                        return value;
                    });
                    dispatch(success(hashMap));
                },
                error => {
                    console.log(error);
                    dispatch(failure(error));
                }
            );
    };

    function request(value) { return { type: favoritesConstants.ADD_FAVORITES_REQUEST, value } }
    function success(value) { return { type: favoritesConstants.ADD_FAVORITES_SUCCESS, value } }
    function failure(error) { return { type: favoritesConstants.ADD_FAVORITES_FAILURE, error } }
}

/**
 * Gets the content of the favorites
 */
function get() {
    return dispatch => {
        dispatch(request());

        favoritesService.get()
            .then(
                value => {
                    let hashMap = {};
                    value.products.map((value) => {
                        if (value !== null) hashMap[value.id_product_favorites] = value;
                        return value;
                    });
                    dispatch(success(hashMap));
                },
                error => {
                    console.log(error);
                    dispatch(failure(error));
                }
            );
    };

    function request(value) { return { type: favoritesConstants.GET_FAVORITES_REQUEST, value } }
    function success(value) { return { type: favoritesConstants.GET_FAVORITES_SUCCESS, value } }
    function failure(error) { return { type: favoritesConstants.GET_FAVORITES_FAILURE, error } }
}