export const cartConstants = {
    ADD_CART_REQUEST: 'ADD_CART_REQUEST',
    ADD_CART_SUCCESS: 'ADD_CART_SUCCESS',
    ADD_CART_FAILURE: 'ADD_CART_FAILURE',
    REMOVE_CART_REQUEST: 'REMOVE_CART_REQUEST',
    REMOVE_CART_SUCCESS: 'REMOVE_CART_SUCCESS',
    REMOVE_CART_FAILURE: 'REMOVE_CART_FAILURE',
    GET_CART_REQUEST: 'GET_CART_REQUEST',
    GET_CART_SUCCESS: 'GET_CART_SUCCESS',
    GET_CART_FAILURE: 'GET_CART_FAILURE',
    GET_CART_PRICE_REQUEST: 'GET_CART_PRICE_REQUEST',
    GET_CART_PRICE_SUCCESS: 'GET_CART_PRICE_SUCCESS',
    GET_CART_PRICE_FAILURE: 'GET_CART_PRICE_FAILURE',
    ORDER_CART_REQUEST: 'ORDER_CART_REQUEST',
    ORDER_CART_SUCCESS: 'ORDER_CART_SUCCESS',
    ORDER_CART_FAILURE: 'ORDER_CART_FAILURE',
    POPULATE_CART_REQUEST: 'POPULATE_CART_REQUEST',
    POPULATE_CART_SUCCESS: 'POPULATE_CART_SUCCESS',
    POPULATE_CART_FAILURE: 'POPULATE_CART_FAILURE',
};