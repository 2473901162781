import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import './Product.css';
import { cartActions, favoritesActions } from '../../actions';

class Product extends Component {
    constructor(params) {
        super(params);
        this.addToCart = this.addToCart.bind(this);
        this.addToFavorites = this.addToFavorites.bind(this);
    }
    addToCart(product) {
        this.props.addToCart(product);
    }
    addToFavorites(product) {
        this.props.addToFavorites(product);
    }
    render() {
        let product = this.props.product;
        let favoriteButton;
        let cartButton;
        if (this.props.favoritesList && this.props.favoritesList[product.id]) {
            favoriteButton = <button name="addToFavorites"  style={{cursor: "pointer"}} className="favorites liked" onClick={(e) => this.addToFavorites(product)} value={product.id}><svg width="24px" height="24px" class="svg-icon" viewBox="0 0 20 20">
                <path d="M9.719,17.073l-6.562-6.51c-0.27-0.268-0.504-0.567-0.696-0.888C1.385,7.89,1.67,5.613,3.155,4.14c0.864-0.856,2.012-1.329,3.233-1.329c1.924,0,3.115,1.12,3.612,1.752c0.499-0.634,1.689-1.752,3.612-1.752c1.221,0,2.369,0.472,3.233,1.329c1.484,1.473,1.771,3.75,0.693,5.537c-0.19,0.32-0.425,0.618-0.695,0.887l-6.562,6.51C10.125,17.229,9.875,17.229,9.719,17.073"></path>
            </svg></button>
        } else {
            favoriteButton = <button name="removeFromFavorites" style={{cursor: "pointer"}} className="favorites notLiked" onClick={(e) => this.addToFavorites(product)} value={product.id}><svg width="24px" height="24px" class="svg-icon" viewBox="0 0 20 20">
                <path d="M9.719,17.073l-6.562-6.51c-0.27-0.268-0.504-0.567-0.696-0.888C1.385,7.89,1.67,5.613,3.155,4.14c0.864-0.856,2.012-1.329,3.233-1.329c1.924,0,3.115,1.12,3.612,1.752c0.499-0.634,1.689-1.752,3.612-1.752c1.221,0,2.369,0.472,3.233,1.329c1.484,1.473,1.771,3.75,0.693,5.537c-0.19,0.32-0.425,0.618-0.695,0.887l-6.562,6.51C10.125,17.229,9.875,17.229,9.719,17.073 M6.388,3.61C5.379,3.61,4.431,4,3.717,4.707C2.495,5.92,2.259,7.794,3.145,9.265c0.158,0.265,0.351,0.51,0.574,0.731L10,16.228l6.281-6.232c0.224-0.221,0.416-0.466,0.573-0.729c0.887-1.472,0.651-3.346-0.571-4.56C15.57,4,14.621,3.61,13.612,3.61c-1.43,0-2.639,0.786-3.268,1.863c-0.154,0.264-0.536,0.264-0.69,0C9.029,4.397,7.82,3.61,6.388,3.61"></path>
            </svg></button>
        }
        if (this.props.cartList && this.props.cartList[product.id]) {
            cartButton = <button name="addToCart" style={{cursor: "pointer"}} className="cart in" value={product.id} onClick={(e) => this.addToCart(product)}><svg width="24px" height="24px" class="svg-icon" viewBox="0 0 20 20">
                <path d="M17.638,6.181h-3.844C13.581,4.273,11.963,2.786,10,2.786c-1.962,0-3.581,1.487-3.793,3.395H2.362c-0.233,0-0.424,0.191-0.424,0.424v10.184c0,0.232,0.191,0.424,0.424,0.424h15.276c0.234,0,0.425-0.191,0.425-0.424V6.605C18.062,6.372,17.872,6.181,17.638,6.181 M10,3.635c1.493,0,2.729,1.109,2.936,2.546H7.064C7.271,4.744,8.506,3.635,10,3.635 "></path>
            </svg></button>
        } else {
            cartButton = <button name="addToCart" style={{cursor: "pointer"}} className="cart out" value={product.id} onClick={(e) => this.addToCart(product)}><svg width="24px" height="24px" class="svg-icon" viewBox="0 0 20 20">
                <path d="M17.638,6.181h-3.844C13.581,4.273,11.963,2.786,10,2.786c-1.962,0-3.581,1.487-3.793,3.395H2.362c-0.233,0-0.424,0.191-0.424,0.424v10.184c0,0.232,0.191,0.424,0.424,0.424h15.276c0.234,0,0.425-0.191,0.425-0.424V6.605C18.062,6.372,17.872,6.181,17.638,6.181 M13.395,9.151c0.234,0,0.425,0.191,0.425,0.424S13.629,10,13.395,10c-0.232,0-0.424-0.191-0.424-0.424S13.162,9.151,13.395,9.151 M10,3.635c1.493,0,2.729,1.109,2.936,2.546H7.064C7.271,4.744,8.506,3.635,10,3.635 M6.605,9.151c0.233,0,0.424,0.191,0.424,0.424S6.838,10,6.605,10c-0.233,0-0.424-0.191-0.424-0.424S6.372,9.151,6.605,9.151 M17.214,16.365H2.786V7.029h3.395v1.347C5.687,8.552,5.332,9.021,5.332,9.575c0,0.703,0.571,1.273,1.273,1.273c0.702,0,1.273-0.57,1.273-1.273c0-0.554-0.354-1.023-0.849-1.199V7.029h5.941v1.347c-0.495,0.176-0.849,0.645-0.849,1.199c0,0.703,0.57,1.273,1.272,1.273s1.273-0.57,1.273-1.273c0-0.554-0.354-1.023-0.849-1.199V7.029h3.395V16.365z"></path>
            </svg></button>
        }
        return (
            <div className="product" key={product.id}>
                <div style={{position: "relative"}} className="image">
                    <Link to={"/product/" + product.slug}><img style={(product.stocks.N < 1) ? {opacity: 0.5} : {}} alt={product.name} width="200px" height="200px" src={"/images/small/" + product.image}></img>
                    </Link>
                    {product.stocks.N < 1 &&
                        <div style={{position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            fontSize: "x-large",
                            fontWeight: "bold",
                            color: "#757575"}}><Link to={"/product/" + product.slug}>AGOTADO</Link></div>
                    }
                </div>
                <div className="info">
                    <div className="data">
                        <p><Link className="price" to={"/product/" + product.slug}>{product.price.toFixed(2)}<sup>€</sup></Link></p>
                        <p><Link to={"/product/" + product.slug}>{product.name}</Link></p>
                    </div>
                    <div className="actions">
                        {favoriteButton}
                        {cartButton}
                    </div>
                </div>
            </div>
        )
    }
}

function mapState(state) {
    const { alert, cart, favorites } = state;
    const { cartList } = cart;
    const { favoritesList } = favorites;
    return { alert, cartList, favoritesList };
}

const actionCreators = {
    addToCart: cartActions.add,
    addToFavorites: favoritesActions.add
};

const connectedProduct = connect(mapState, actionCreators)(Product)
export default connectedProduct;