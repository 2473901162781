import { combineReducers } from 'redux';
import { product } from './product.reducer';
import { alert } from './alert.reducer';
import { cart } from './cart.reducer';
import { favorites } from './favorites.reducer';
import { authentication } from './authentication.reducer';
import { addresses } from './addresses.reducer';
import { order } from './order.reducer';

const rootReducer = combineReducers({
    alert,
    product,
    cart,
    authentication,
    favorites,
    addresses,
    order
});

export default rootReducer;