import { productConstants } from '../constants';

export function product(state = {getting: false, hasMore: true, error: false}, action) {
    switch (action.type) {
        case productConstants.LIST_PRODUCT_REQUEST:
            return { ...state, getting: true };
        case productConstants.LIST_PRODUCT_SUCCESS:
            return {
                ...state,
                getting: false,
                hasMore: (action.value.products.length === 20) ? true : false,
                productUrl: action.value.params.productUrl,
                orderParam: action.value.params.orderParam,
                order: action.value.params.order,
                limit: action.value.params.limit,
                offset: action.value.params.offset,
                productList: action.value.products
            };
        case productConstants.LIST_PRODUCT_APPEND:
            return {
                ...state,
                getting: false,
                hasMore: (action.value.products.length === 20) ? true : false,
                productUrl: action.value.params.productUrl,
                orderParam: action.value.params.orderParam,
                order: action.value.params.order,
                limit: action.value.params.limit,
                offset: action.value.params.offset,
                error: false,
                productList: state.productList.concat(action.value.products)
            };
        case productConstants.LIST_PRODUCT_FAILURE:
            return {
                ...state,
                error: true
            };
        case productConstants.GET_PRODUCT_REQUEST:
            return { ...state, getting: true };
        case productConstants.GET_PRODUCT_SUCCESS:
            return {
                ...state,
                getting: false,
                product: action.value.product
            };
        case productConstants.GET_PRODUCT_FAILURE:
            return state;
        default:
            return state
    }
}