import React, { Component } from 'react';
import { cartActions } from '../../actions';
import { connect } from 'react-redux';
import './Cart.css';
import { Link } from "react-router-dom";
import brands from '../../resources/brands.json';
import colors from '../../resources/colors.json';
import categories from '../../resources/categories.json';

class Cart extends Component {
    constructor(params) {
        super(params);
        this.addToCart = this.addToCart.bind(this);
        this.removeFromCart = this.removeFromCart.bind(this);
    }
    addToCart(product) {
        this.props.addToCart(product, 1);
    }
    removeFromCart(product) {
        this.props.addToCart(product, -1);
    }
    render() {
        let cartList = [];
        for (let product in this.props.cartList) {
            cartList.push(this.props.cartList[product]); //Creates an array from the cartList elements to be able to map through it
        }
        let totalPrice = 0;
        let endPrice = 'Gratis';
        return (
            <div id="cart">
                {cartList.length > 0 &&/* map on the render */
                    <div className="productsCart">
                        {cartList.map((product, index) =>
                            <div className="productCart" key={index}>
                                <div className="image"><Link to={"/product/" + product.slug}><img alt={product.name} width="100" height="100" src={"/images/small/" + product.image}></img></Link></div>
                                <div className="data">
                                    <div className="name">{product.name} <span style={{ backgroundColor: colors[product.id_color].color }} className="colorCircle"></span></div>
                                    <div className="category">{categories[product.id_product_type]}</div>
                                    <div className="brand">{brands[product.id_brand]}</div>
                                </div>
                                <div className="priceData">
                                    <div className="price">{product.price.toFixed(2)}<sup>€</sup></div>
                                    <div style={{ display: "none" }}>{product.id = product.id_product_cart}</div>
                                    <div className="total">
                                        <button className="remove" value={product.id} onClick={(e) => this.removeFromCart(product)}>
                                            <svg width="20px" height="20px" class="svg-icon" viewBox="0 0 20 20">
                                                <path d="M8.388,10.049l4.76-4.873c0.303-0.31,0.297-0.804-0.012-1.105c-0.309-0.304-0.803-0.293-1.105,0.012L6.726,9.516c-0.303,0.31-0.296,0.805,0.012,1.105l5.433,5.307c0.152,0.148,0.35,0.223,0.547,0.223c0.203,0,0.406-0.08,0.559-0.236c0.303-0.309,0.295-0.803-0.012-1.104L8.388,10.049z"></path>
                                            </svg>
                                        </button>
                                        {product.total}
                                        <button value={product.id} onClick={(e) => this.addToCart(product)}>
                                            <svg width="20px" height="20px" class="svg-icon" viewBox="0 0 20 20">
                                                <path d="M11.611,10.049l-4.76-4.873c-0.303-0.31-0.297-0.804,0.012-1.105c0.309-0.304,0.803-0.293,1.105,0.012l5.306,5.433c0.304,0.31,0.296,0.805-0.012,1.105L7.83,15.928c-0.152,0.148-0.35,0.223-0.547,0.223c-0.203,0-0.406-0.08-0.559-0.236c-0.303-0.309-0.295-0.803,0.012-1.104L11.611,10.049z"></path>
                                            </svg>
                                        </button>
                                    </div>
                                    <div style={{ display: "none" }}>{totalPrice += product.total * product.price}</div>
                                </div>
                            </div>
                        )}
                    </div>
                }
                {cartList.length > 0 && (
                    <div className="result">
                        <h2>Total</h2>
                        <div className="subtotal" ><div>Subtotal</div><div className="calculatedPrice" >{(Math.round(totalPrice * 100) / 100).toFixed(2)}<sup>€</sup></div></div>
                        <div className="shipment" ><div>Envío</div><div className="calculatedPrice" >{((Math.round(totalPrice * 100) / 100).toFixed(2) >= 30) ? <span><span className="calculation">{endPrice = totalPrice}</span>Gratis</span> : <span><span className="calculation">{endPrice = totalPrice + 3}</span>3.00<sup>€</sup></span>}</div></div>
                        {((Math.round(totalPrice * 100) / 100).toFixed(2) >= 30) ? <span></span> : <div className="freeShipment"><span></span><span><span>Quedan </span><span>{(30 - (Math.round(totalPrice * 100) / 100)).toFixed(2)}</span><span><sup>€</sup> para envío gratuito</span></span></div> }
                        <div className="total" ><div>Total</div><div className="calculatedPrice" >{(Math.round(endPrice * 100) / 100).toFixed(2)}<sup>€</sup></div></div>
                        <Link to="/checkout"><button>Comenzar pedido</button></Link>
                    </div>
                )}
                {
                    cartList.length <= 0 && <h2 style={{ textAlign: "center", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>Bolsa de la compra vacía</h2> //Context usage for displaying the word empty when there aren't no elements on the cart
                }
            </div>
        )
    }
}

function mapState(state) {
    const { alert, cart } = state;
    const { cartList } = cart;
    return { alert, cartList };
}

const actionCreators = {
    addToCart: cartActions.add,
    removeFromCart: cartActions.remove,
};

const connectedCart = connect(mapState, actionCreators)(Cart)
export default connectedCart;
