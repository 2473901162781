import React, { Component } from 'react';
import { addressesActions } from '../../actions';
import { connect } from 'react-redux';
import './CreateAddress.css';

class CreateAddress extends Component {
    constructor(params) {
        super(params);
        this.state = {
            name: '',
            street: '',
            city: '',
            state: '',
            country: 'España',
            postal_code: '',
            telephone: '',
            submitted: false,
            modified: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }
    handleSubmit(e) {
        e.preventDefault();
        this.setState({ submitted: true });
        const { name, street, city, state, country, postal_code, telephone } = this.state;
        let data = {
            name, street, city, state, country, postal_code, telephone
        }
        this.props.addToAddresses(data);
    }
    render() {
        const { name, street, city, state, country, postal_code, telephone, submitted } = this.state;
        return (
            <div id="createAddress">
                <div id="createAddressPage">
                    <h2>Crear dirección</h2>
                    <form onSubmit={this.handleSubmit}>
                        {submitted && (!name || name.length < 2) &&
                            <div className="textError">El nombre es necesario (mínimo 2 carácteres)</div>
                        }
                        <div className={(submitted && !name ? ' has-error formElement' : 'formElement')}>
                            <div>Nombre: </div>
                            <input type="text" placeholder="Nombre" name="name" value={name} onChange={this.handleChange} />
                        </div>
                        {submitted && (!street || street.length < 2) &&
                            <div className="textError">La calle es necesaria (mínimo 2 carácteres)</div>
                        }
                        <div className={(submitted && !street ? ' has-error formElement' : 'formElement')}>
                            <div>Calle: </div>
                            <input type="text" placeholder="Calle" name="street" value={street} onChange={this.handleChange} />
                        </div>
                        {submitted && (!city || city.length < 2) &&
                            <div className="textError">La ciudad es necesaria (mínimo 2 carácteres)</div>
                        }
                        <div className={(submitted && !city ? ' has-error formElement' : 'formElement')}>
                            <div>Ciudad: </div>
                            <input type="text" placeholder="Ciudad" name="city" value={city} onChange={this.handleChange} />
                        </div>
                        <div className="formElement">
                            <div>Provincia / Región: </div>
                            <input type="text" placeholder="Provincia / Región" name="state" value={state} onChange={this.handleChange} />
                        </div>
                        {submitted && (!country || country.length < 2) &&
                            <div className="textError">El país es necesario (mínimo 2 carácteres)</div>
                        }
                        <div className={(submitted && !country ? ' has-error formElement' : 'formElement')}>
                            <div>País: </div>
                            <input type="text" disabled placeholder="País" name="country" value="España" onChange={this.handleChange} />
                        </div>
                        {submitted && (!postal_code || postal_code.length < 2) &&
                            <div className="textError">El código postal es necesario (mínimo 2 carácteres)</div>
                        }
                        <div className={(submitted && !postal_code ? ' has-error formElement' : 'formElement')}>
                            <div>Código postal: </div>
                            <input type="text" placeholder="Código Postal" name="postal_code" value={postal_code} onChange={this.handleChange} />
                        </div>
                        <div className="formElement">
                            <div>Teléfono: </div>
                            <input type="text" placeholder="Teléfono" name="telephone" value={telephone} onChange={this.handleChange} />
                        </div>
                        <button>Guardar dirección</button>
                    </form>
                </div>
            </div>
        )
    }
}

function mapState(state) {
    const { alert, addresses } = state;
    const { addressesList } = addresses;
    return { alert, addressesList };
}

const actionCreators = {
    addToAddresses: addressesActions.add
};

const connectedAddresses = connect(mapState, actionCreators)(CreateAddress)
export default connectedAddresses;
