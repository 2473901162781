import config from '../helpers/config.json';
import { authHeader } from '../helpers';

export const addressesService = {
    get,
    add,
    remove,
    update
};


function get() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.user && user.user.token) {
        const requestOptions = {
            method: "GET",
            headers: authHeader()
        };
        return fetch(`${config.apiUrl}/addresses/`,
            requestOptions).then(handleResponse);
    } else {
        return Promise.reject();
    }
}

function add(address) {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.user && user.user.token) {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authHeader().Authorization
            },
            body: JSON.stringify({
                name: address.name,
                street: address.street,
                city: address.city,
                state: address.state,
                country: "España",
                postal_code: address.postal_code,
                telephone: address.telephone
            })
        };
        return fetch(`${config.apiUrl}/addresses/add`, requestOptions)
            .then(handleResponse);
    } else {
        return Promise.reject();
    }
}


function remove(addressId) {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.user && user.user.token) {
        const requestOptions = {
            method: "DELETE",
            headers: authHeader()
        };
        return fetch(`${config.apiUrl}/addresses/` + addressId,
            requestOptions).then(handleResponse);
    } else {
        return Promise.reject();
    }
}

function update(id, address) {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.user && user.user.token) {
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authHeader().Authorization
            },
            body: JSON.stringify({
                name: address.name,
                street: address.street,
                city: address.city,
                state: address.state,
                country: "España",
                postal_code: address.postal_code,
                telephone: address.telephone
            })
        };
        return fetch(`${config.apiUrl}/addresses/` + id, requestOptions)
            .then(handleResponse);
    } else {
        return Promise.reject();
    }
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                localStorage.removeItem('user');
                window.location.reload(true);
            }
            const error = (data && data.error) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
