import React, { Component } from 'react';
import { orderActions } from '../../actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import './Orders.css';

class Orders extends Component {
    constructor(params) {
        super(params);
        this.props.getOrders();
    }
    render() {
        let ordersList = this.props.ordersList;
        if (ordersList) {
            for (let i in ordersList) {
                ordersList[i].products = [];
                for (let j in ordersList[i].ProductsOrdered) {
                    let product = ordersList[i].Products.find(Product => {
                        return Product.ID === ordersList[i].ProductsOrdered[j].IdProduct
                    })
                    let image = ordersList[i].Images.find(Image => {
                        return Image.ID === product.IdImage
                    })
                    ordersList[i].products.push({
                        orderedProduct: ordersList[i].ProductsOrdered[j],
                        product,
                        image
                    });
                }
            }
        }
        return (
            <div id="orders">
                { ordersList && ordersList.length > 0 &&
                    <div className="ordersList">
                        <div>
                            <h3>Pedidos</h3>
                        </div>
                        {ordersList.map((order, index) =>
                            <div key={index} className="order">
                                <div className="orderHeader">
                                    <div>
                                        <p className="bold">Pedido realizado el día</p>
                                        <p>{order.time.split(" ")[0]}</p>
                                    </div>
                                    <div>
                                        <p className="bold">Total</p>
                                        <p>{(order.total / 100).toFixed(2)}<sup>€</sup></p>
                                    </div>
                                    <div>
                                        <p className="bold">Estado:</p>
                                        <p><span>{(order.arrived === 1) ? "Entregado" : (order.shipped === 1) ? "Enviado" : (order.paid) ? "Pagado" : "Por pagar"}</span></p>
                                    </div>
                                    <div>
                                        <p className="bold">Identificador pedido</p>
                                        <p><span>{order.identifier}</span></p>
                                    </div>
                                </div>
                                <div className="orderData">
                                    <div className="orderDirections">
                                        <p className="bold">Dirección de envío</p>
                                        <p className="name" >{order.Address.Name}</p>
                                        <p>{order.Address.Street}</p>
                                        <p>{order.Address.City}{(order.Address.State) ? ", " + order.Address.State + " " : " "}{order.Address.PostalCode}</p>
                                        <p>{order.Address.Country}</p>
                                        <p>{order.Address.Telephone && "Teléfono: " + order.Address.Telephone}</p>
                                    </div>
                                    <div className="orderProductData">
                                        <p  className="bold">Producto(s)</p>
                                        <div className="orderProducts">
                                            { order.products.map((product, index) =>
                                                <div className="orderProduct" key={index}>
                                                    <div className="image"><Link to={"/product/" + product.product.Slug}><img alt={product.product.Name} width="100" height="100" src={"/images/small/" + product.image.Url}></img></Link></div>
                                                    <div className="Data product">
                                                        <p><Link to={"/product/" + product.product.Slug}>{product.product.Name}</Link></p>
                                                        <p>Precio: {(product.orderedProduct.ProductPrice).toFixed(2)}<sup>€</sup></p>
                                                        <p>Cantidad: {product.orderedProduct.ProductQuantity}</p>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                }
                { ordersList && ordersList.length < 1 &&
                    <div className="orderListEmpty">
                        <p style={{textAlign: "center", position: "absolute", top:"50%", left:"50%", transform:"translate(-50%, -50%)"}}>No has realizado aún ningún pedido.</p>
                    </div>
                }
            </div>
        )
    }
}

function mapState(state) {
    const { alert, order } = state;
    const { ordersList } = order;
    return { alert, ordersList };
}

const actionCreators = {
    getOrders: orderActions.list
};

const connectedOrders = connect(mapState, actionCreators)(Orders)
export default connectedOrders;
