import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { cartActions, alertActions } from '../../actions';
import Addresses from '../Addresses/Addresses';
import './Checkout.css';

class Checkout extends Component {
    constructor(props) {
        super(props);
        this.state = { //Usage of the state for the form
            idAddress: '',
            email: ''
        }
        this.adviseLoggedIn = this.adviseLoggedIn.bind(this);
    }
    adviseLoggedIn(text) {
        this.props.success(text);
        return true;
    }
    render() {
        return (
            <div id="checkout">
                { !this.props.loggedIn && this.adviseLoggedIn("Debes de iniciar sesión para poder completar un pedido.") &&
                    <Redirect to="/login" />
                }
                <div className="checkoutBox">
                    <Addresses select={true} />
                    { this.props.addressesList && (this.props.addressesList.length > 0) && this.props.selectedAddress !== undefined && <h3>Datos del pedido</h3> }
                    { this.props.addressesList && (this.props.addressesList.length > 0) && this.props.selectedAddress !== undefined &&
                    <div className="paymentData">
                        <div className="dataBlock">
                            <div className="contentBlock chosenAddress">
                                <h5>Dirección de envío</h5>
                                <p className="name" >{this.props.addressesList[this.props.selectedAddress].name}</p>
                                <p>{this.props.addressesList[this.props.selectedAddress].street}</p>
                                <p>{this.props.addressesList[this.props.selectedAddress].city}{(this.props.addressesList[this.props.selectedAddress].state) ? ", " + this.props.addressesList[this.props.selectedAddress].state + " " : " "}{this.props.addressesList[this.props.selectedAddress].postal_code}</p>
                                <p>{this.props.addressesList[this.props.selectedAddress].country}</p>
                                <p>{this.props.addressesList[this.props.selectedAddress].telephone && "Teléfono: " + this.props.addressesList[this.props.selectedAddress].telephone}</p>
                            </div>
                            <div className="contentBlock deliveryTime">
                                <h5>Tiempo estimado de entrega</h5>
                                { this.props.stockAvailable &&
                                    <div>
                                        <p>De 2 a 3 días laborables</p>
                                    </div>
                                }
                                { !this.props.stockAvailable &&
                                    <div>
                                        <p>Como hay productos en tu bolsa que no se encuentran disponibles actualmente, se le pondrá en lista de espera y se le enviará el pedido lo antes posible (tiempo máximo 15 dias laborables).</p>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="dataBlock rightBlock">
                            <div className="contentBlock finalPrice">
                                <h5>Precio final</h5>
                                <p className="price">{(this.props.totalPrice) ? this.props.totalPrice.toFixed(2) : "Cargando "}<sup>€</sup></p>
                                <span>iva incluido*</span>
                            </div>
                            <div className="contentBlock paymentButton">
                                <form name="from" action="https://sis.redsys.es/sis/realizarPago" method="POST">
                                    <input type="hidden" name="Ds_SignatureVersion" value="HMAC_SHA256_V1"/>
                                    <input type="hidden" name="Ds_MerchantParameters" value={this.props.transferData}/>
                                    <input type="hidden" name="Ds_Signature" value={this.props.signature}/>
                                    <button>Realizar pago</button>
                                </form>
                                <p>Será redireccionado a la pasarela de pago.</p>
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </div>
        );
    }
}
function mapState(state) {
    const { alert, authentication, addresses, cart } = state;
    const { loggedIn, user } = authentication;
    const { addressesList, selectedAddress } = addresses;
    const { totalPrice, stockAvailable, transferData, signature } = cart;
    return { alert, loggedIn, user, addressesList, selectedAddress, totalPrice, stockAvailable, transferData, signature};
}

const actionCreators = {
    order: cartActions.order,
    success: alertActions.success,
};

const connectedCheckout = connect(mapState, actionCreators)(Checkout);
export default connectedCheckout;
