import React, { Component } from 'react';
import { cartActions, favoritesActions } from '../../actions';
import { connect } from 'react-redux';
import './Favorites.css';
import { Link } from "react-router-dom";
import brands from '../../resources/brands.json';
import colors from '../../resources/colors.json';
import categories from '../../resources/categories.json';

class Favorites extends Component {
    constructor(params) {
        super(params);
        this.addToCart = this.addToCart.bind(this);
        this.addToFavorites = this.addToFavorites.bind(this);
    }
    addToCart(product) {
        this.props.addToCart(product, 1);
    }
    addToFavorites(product) {
        this.props.addToFavorites(product);
    }
    render() {
        let favoritesList = [];
        for (let product in this.props.favoritesList) {
            favoritesList.push(this.props.favoritesList[product]); //Creates an array from the cartList elements to be able to map through it
        }
        let totalPrice = 0;
        return (
            <div id="favorites">
                { favoritesList.length > 0 &&/* map on the render */
                    <div className="productsFavorites">
                        { favoritesList.map((product, index) =>
                        <div className="productFavorites" key={index}>
                            <div className="image"><Link to={"/product/" + product.slug}><img alt={product.name} width="100" height="100" src={"/images/small/" + product.image}></img></Link></div>
                            <div className="data">
                                <div className="name">{product.name} <span style={{backgroundColor: colors[product.id_color].color}} className="colorCircle"></span></div>
                                <div className="category">{categories[product.id_product_type]}</div>
                                <div className="brand">{brands[product.id_brand]}</div>
                            </div>
                            <div className="priceData">
                                <div className="price">{product.price.toFixed(2)}<sup>€</sup></div>
                                <div style={{display: "none"}}>{product.id = product.id_product_favorites}</div>
                                <div className="total">
                                    <button value={product.id} onClick={(e) => this.addToCart(product)}>
                                        Añadir a la bolsa
                                    </button>
                                    <button value={product.id} onClick={(e) => this.addToFavorites(product)}>
                                        Quitar de favoritos
                                    </button>
                                </div>
                                <div style={{display: "none"}}>{totalPrice += product.total * product.price}</div>
                            </div>
                        </div>
                        )}
                    </div>
                }
                {
                    favoritesList.length <= 0 && <h2 style={{textAlign: "center", position: "absolute", top:"50%", left:"50%", transform:"translate(-50%, -50%)"}}>Lista de deseados vacía</h2> //Context usage for displaying the word empty when there aren't no elements on the cart
                }
            </div>
        )
    }
}

function mapState(state) {
    const { alert, cart, favorites } = state;
    const { cartList } = cart;
    const { favoritesList } = favorites;
    return { alert, cartList, favoritesList };
}

const actionCreators = {
    addToCart: cartActions.add,
    addToFavorites: favoritesActions.add
};

const connectedFavorites = connect(mapState, actionCreators)(Favorites)
export default connectedFavorites;
