import config from '../helpers/config.json';
import { authHeader } from '../helpers';

let favorites = JSON.parse(localStorage.getItem('favorites'));
if (!favorites) {
    localStorage.setItem('favorites',JSON.stringify({products: []}));
    favorites = {products: []};
}
export const favoritesService = {
    get,
    add
};

function checkFavorites() {
    if (!favorites) {
        favorites = JSON.parse(localStorage.getItem('favorites'));
    }
    return favorites;
}

function get() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.user && user.user.token) {
        const requestOptions = {
            method: "GET",
            headers: authHeader()
        };
        return fetch(`${config.apiUrl}/favorites/`,
            requestOptions).then(handleResponse);
    } else {
        favorites = checkFavorites()
        return Promise.resolve(favorites);
    }
}

function add(product) {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.user && user.user.token) {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authHeader().Authorization
            },
            body: JSON.stringify({
                id_product: product.id,
                id_size: 1
            })
        };
        return fetch(`${config.apiUrl}/favorites/add`, requestOptions)
            .then(handleResponse);
    } else {
        checkFavorites();
        if (favorites.products[product.id] == null) {
            favorites.products[product.id] = product;
            favorites.products[product.id].id_product_favorites = product.id;
        } else {
            delete favorites.products[product.id];
        }
        localStorage.setItem('favorites', JSON.stringify(favorites));
        return Promise.resolve(favorites);
    }
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                localStorage.removeItem('user');
                window.location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
