import config from '../helpers/config.json';
import { authHeader } from '../helpers';

let cart = JSON.parse(localStorage.getItem('cart'));
if (!cart) {
    localStorage.setItem('cart',JSON.stringify({ products: [] }));
    cart = { products: [] };
}
export const cartService = {
    get,
    add,
    remove,
    order,
    populate,
    getPrice
};

function checkCart() {
    if (!cart) {
        cart = JSON.parse(localStorage.getItem('cart'));
    }
    return cart;
}

function get() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.user && user.user.token) {
        const requestOptions = {
            method: "GET",
            headers: authHeader()
        };
        return fetch(`${config.apiUrl}/bag/`,
            requestOptions).then(handleResponse);
    } else {
        cart = checkCart()
        return Promise.resolve(cart);
    }
}


function getPrice(idAddress) {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.user && user.user.token) {
        const requestOptions = {
            method: "GET",
            headers: authHeader()
        };
        return fetch(`${config.apiUrl}/bag/price/` + idAddress,
            requestOptions).then(handleResponse);
    } else {
        cart = checkCart()
        return Promise.resolve(cart);
    }
}

function add(product, quantity = 1) {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.user && user.user.token) {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authHeader().Authorization
            },
            body: JSON.stringify({
                id_product: product.id,
                quantity: quantity,
                id_size: 1
            })
        };
        return fetch(`${config.apiUrl}/bag/add`, requestOptions)
            .then(handleResponse);
    } else {
        checkCart();
        if (cart.products[product.id] == null) {
            cart.products[product.id] = product;
            cart.products[product.id].id_product_cart = product.id;
            cart.products[product.id].total = 0;
            cart.products[product.id].total += quantity;
        } else {
            cart.products[product.id].total += quantity;
        }
        if (cart.products[product.id].total < 1) {
            delete cart.products[product.id];
        }
        localStorage.setItem('cart', JSON.stringify(cart));
        return Promise.resolve(cart);
    }
}

function populate() {
    let user = JSON.parse(localStorage.getItem('user'));
    let cartContents = JSON.parse(localStorage.getItem('cart')).products;
    let cartUpload = {bag: []};
    for (let i in cartContents) {
        if (cartContents[i] !== null) {
            cartUpload.bag.push({
                id_product: cartContents[i].id_product_cart,
                id_size: 1,
                quantity: cartContents[i].total
            });
        }
    }
    if (cartUpload.bag.length > 0) {
        if (user && user.user && user.user.token) {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authHeader().Authorization
                },
                body: JSON.stringify(cartUpload)
            };
            return fetch(`${config.apiUrl}/bag/populate`, requestOptions)
                .then(handleResponse)
                .then(cart => {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('cart', JSON.stringify({products: []}));
                    return cart;
                });;
        } else {
            return Promise.reject();
        }
    } else {
        return get();
    }
}

function remove(productId) {
    checkCart();
    if (cart[productId] !== null && cart[productId].total !== 0) {
        cart[productId].total--;
        if (cart[productId].total === 0) {
            delete cart[productId];
        }
    }
    localStorage.setItem('cart', JSON.stringify(cart));
    return Promise.resolve(productId);
}

function order(name, address, cardNumber) {
    cart = {};
    localStorage.setItem('cart', JSON.stringify(cart));
    return Promise.resolve({name, address, cardNumber});
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                localStorage.removeItem('user');
                window.location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
