import { favoritesConstants } from '../constants';

export function favorites(state = {}, action) {
    switch (action.type) {
        case favoritesConstants.GET_FAVORITES_REQUEST:
            return { state };
        case favoritesConstants.ADD_FAVORITES_SUCCESS:
        case favoritesConstants.GET_FAVORITES_SUCCESS:
            return {
                ...state,
                favoritesList: action.value
            };
        case favoritesConstants.GET_FAVORITES_FAILURE:
            return state;
      default:
        return state
    }
  }