import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { userActions } from '../../actions';

class Register extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: {
                email: '',
                password: '',
                repeatPassword: ''
            },
            submitted: false,
            passwordBadRepeated: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        const { name, value } = event.target;
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                [name]: value
            }
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        this.setState({ submitted: true, passwordBadRepeated: false });
        const { user } = this.state;
        if (user.email && user.password && user.password === user.repeatPassword) {
            this.props.register(user.email, user.password);
        } else if (user.password === user.repeatPassword) {
            this.setState({passwordBadRepeated: true}) ;
        }
    }

    render() {
        const { user, submitted, passwordBadRepeated } = this.state;
        return (
            <div id="register">
                { this.props.loggedIn &&
                    <Redirect to="/account" />
                }
                <h2>Register</h2>
                <form name="form-register" onSubmit={this.handleSubmit}>
                    <div>
                        {submitted && !user.email &&
                            <p className="textError" >Email is required</p>
                        }
                        <input type="text" name="email" placeholder="Email" value={user.email} onChange={this.handleChange} />
                    </div>
                    <div>
                        {submitted && !user.password &&
                            <p className="textError" >Password is required</p>
                        }
                        <input type="password" name="password" placeholder="Password" value={user.password} onChange={this.handleChange} />
                    </div>
                    <div>
                        {submitted && !user.repeatPassword &&
                            <p className="textError" >Repeat password is required</p>
                        }
                        {passwordBadRepeated &&
                            <p className="textError" >Passwords do not match</p>
                        }
                        <input type="password" name="repeatPassword" placeholder="Repeat password" value={user.repeatPassword} onChange={this.handleChange} />
                    </div>
                    <div>
                        { this.props.loggingIn && <div className="loading-box">
                            <div className="loading">
                                <svg width="24px" height="24px" className="svg-icon" viewBox="0 0 20 20">
                                    <path d="M9.719,17.073l-6.562-6.51c-0.27-0.268-0.504-0.567-0.696-0.888C1.385,7.89,1.67,5.613,3.155,4.14c0.864-0.856,2.012-1.329,3.233-1.329c1.924,0,3.115,1.12,3.612,1.752c0.499-0.634,1.689-1.752,3.612-1.752c1.221,0,2.369,0.472,3.233,1.329c1.484,1.473,1.771,3.75,0.693,5.537c-0.19,0.32-0.425,0.618-0.695,0.887l-6.562,6.51C10.125,17.229,9.875,17.229,9.719,17.073 M6.388,3.61C5.379,3.61,4.431,4,3.717,4.707C2.495,5.92,2.259,7.794,3.145,9.265c0.158,0.265,0.351,0.51,0.574,0.731L10,16.228l6.281-6.232c0.224-0.221,0.416-0.466,0.573-0.729c0.887-1.472,0.651-3.346-0.571-4.56C15.57,4,14.621,3.61,13.612,3.61c-1.43,0-2.639,0.786-3.268,1.863c-0.154,0.264-0.536,0.264-0.69,0C9.029,4.397,7.82,3.61,6.388,3.61"></path>
                                </svg>
                            </div>
                        </div>
                        }
                        <button>Register</button><br/>
                        <Link to="/login">Login</Link>
                    </div>
                </form>
            </div>
        );
    }
}

function mapState(state) {
    const { loggingIn, loggedIn } = state.authentication;
    return { loggingIn, loggedIn };
}

const actionCreators = {
    register: userActions.register
}

const connectedRegisterPage = connect(mapState, actionCreators)(Register);
export default connectedRegisterPage;