import React, { Component } from 'react';
import ProductList from '../ProductList/ProductList';
import './Home.css';

class Home extends Component {
    render() {
        return (
            <div id="home">
                <div className="banner">
                    <h2>Tu tienda favorita de complementos, accesorios y moda de firmas de joyería y bisutería exclusivas</h2>
                    <div>
                        <svg width="80px" height="80px" version="1.1" viewBox="0 0 255 255">
                            <g >
                                <path d="m101.83 29.297a98.84 98.84 0 0 0-98.84 98.84 98.84 98.84 0 0 0 98.84 98.84 98.84 98.84 0 0 0 98.84-98.84 98.84 98.84 0 0 0-98.84-98.84zm-0.27148 23.604a75.506 75.506 0 0 1 75.508 75.508 75.506 75.506 0 0 1-75.508 75.506 75.506 75.506 0 0 1-75.506-75.506 75.506 75.506 0 0 1 75.506-75.508z" stroke-width=".90619"/>
                                <circle cx="177.33" cy="128.41" r="75.506" stroke-width=".69226"/>
                            </g>
                        </svg>
                    </div>
                </div>
                <ProductList />
            </div>
        )
    }
}

export default Home;