import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import './ProductPage.css';
import { cartActions, productActions, favoritesActions } from '../../actions';
import brands from '../../resources/brands.json';
import colors from '../../resources/colors.json';
import materials from '../../resources/materials.json';
import categories from '../../resources/categories.json';


class ProductPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            slug: props.match.params.slug,
            mainImage: "",
            quantity: 1
        }
        this.changeImage = this.changeImage.bind(this);
        this.setMainImage = this.setMainImage.bind(this);
        this.addToCart = this.addToCart.bind(this);
        this.addToFavorites = this.addToFavorites.bind(this);
        this.handleQuantityChange = this.handleQuantityChange.bind(this);
        this.props.getProduct(this.state.slug);
    }
    changeImage(image) {
        this.setMainImage(image);
    }
    setMainImage(image) {
        this.setState({
            ...this.state,
            mainImage: "../small/" + image
        });
        let a = this;
        setTimeout(function(){
            a.setState({
                ...a.state,
                mainImage: image
            });
        }, 50);
    }
    handleQuantityChange(e) {
        this.setState({
            ...this.state,
            quantity: parseInt(e.target.value)
        })
    }
    addToCart(product) {
        this.props.addToCart(product, this.state.quantity);
    }
    addToFavorites(product) {
        this.props.addToFavorites(product);
    }
    render() {
        let product = (this.props.product.product !== undefined) ? this.props.product.product : {id_color: 0, id_brand: 0};
        let description = [];
        let stockMessage;
        if (product.stocks && product.stocks.N && product.stocks.N > 0) {
            if (product.stocks.N < 5) {
                stockMessage = <p className="fewAvailable">Sólo queda(n) {product.stocks.N} en stock.</p>
            } else {
                stockMessage = <p className="available">En stock</p>
            }
        } else {
            stockMessage = <p className="notAvailable">Temporalmente sin stock.</p>
        }
        let cutImage = this.state.mainImage.split('/');
        cutImage = cutImage[cutImage.length - 1];
        if (product.images && product.images[0] && !product.images.includes(cutImage)) this.setMainImage(product.images[0]);
        if (product.description) description = product.description.split("-");
        return (
            <div className="productPage" key={product.id}>
                <div className="image">
                    <div className="imageBlock">
                        { this.state.mainImage !== "" &&
                            <img className="mainImage" alt={product.name} src={"/images/full/" + this.state.mainImage}></img>
                        }
                        <div className="imagesBlock">
                            { product.images && product.images.length > 0 && product.images.map((image, index) =>
                                <img className="smallImageBlock" onClick={(e) => this.changeImage(image)} style={(image === this.state.mainImage) ? {border: "solid 2px orange"} : {border: "solid 2px silver"}} key={index} alt="Product" src={"/images/small/" + image}/>
                            )}
                        </div>
                    </div>
                </div>
                <div className="info">
                    <div className="data">
                        <div className="title">
                            <h2>{product.name}</h2>
                            <h4>{categories[product.id_product_type]} de {brands[product.id_brand]}</h4>
                        </div>
                        <div className="moreData">
                            <h5 className="details">Color: <span className="normal">{colors[product.id_color].name}</span></h5>
                            <h5 className="colorText">Colores disponibles: </h5>
                            <div className="colors">
                                { product.similar_products && product.similar_products[0].Slug && product.similar_products.map((similarProduct, index) =>
                                    <Link title={colors[similarProduct.IdColor].name} style={{marginRight: "10px", marginTop: "10px"}} to={"/product/" + similarProduct.Slug} key={index} >
                                        <div style={(similarProduct.Slug === product.slug) ? {border: "solid 2px orange", backgroundColor: colors[similarProduct.IdColor].color } : {border: "solid 2px silver", backgroundColor: colors[similarProduct.IdColor].color}} className="colorBox"></div>
                                    </Link>
                                )}
                            </div>
                            <h5>Material: <span className="normal">{materials[product.id_material]}</span></h5>
                            <div className="details">
                                { description && description[0] &&
                                    <p className="descriptionIntro">{description[0]}</p>
                                }
                                { description && description[1] && description.shift() &&
                                    <ul className="description">
                                        { description.map((row, index) =>
                                            <li className="descriptionItem" key={index} >{row}</li>
                                        )}
                                    </ul>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="actions">
                        <h3 className="details"><span className="soft">Precio: </span><span className="price">{product.price && product.price.toFixed(2)}<sup>€</sup></span></h3>
                        {stockMessage}
                        <div className="actionButtons">
                            <div className="quantitySelector">
                                <p>Cantidad: <select onChange={this.handleQuantityChange} >
                                    {  product.stocks && product.stocks.N && product.stocks.N > 0 && [...Array(product.stocks.N)].map((e, index) =>
                                        <option key={index + 1} selected={this.state.quantity === index + 1} value={index + 1}>{index + 1}</option>
                                    )}
                                    { product.stocks && product.stocks.N && product.stocks.N < 1 &&
                                        <option key="1" selected={true} value={1}>{1}</option>
                                    }
                                </select></p>
                            </div>
                            <button className="addToBag" value={product.id} onClick={(e) => this.addToCart(product)}><svg width="30px" height="30px" class="svg-icon" viewBox="0 0 20 20">
                <path d="M17.638,6.181h-3.844C13.581,4.273,11.963,2.786,10,2.786c-1.962,0-3.581,1.487-3.793,3.395H2.362c-0.233,0-0.424,0.191-0.424,0.424v10.184c0,0.232,0.191,0.424,0.424,0.424h15.276c0.234,0,0.425-0.191,0.425-0.424V6.605C18.062,6.372,17.872,6.181,17.638,6.181 M10,3.635c1.493,0,2.729,1.109,2.936,2.546H7.064C7.271,4.744,8.506,3.635,10,3.635 "></path>
            </svg> <span>Añadir a la bolsa</span><span></span></button>
                            {/* <button className="buyNow" ><svg width="25px" height="25px" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 640 640"><path d="M560.109 317.531L79.891 640V0z"/></svg>
                                <span>Comprar ya</span><span></span></button> */}
                            <button onClick={(e) => this.addToFavorites(product)} className="addToFavorites" ><svg width="21px" height="21px" className="svg-icon" viewBox="0 0 20 20">
                <path d="M9.719,17.073l-6.562-6.51c-0.27-0.268-0.504-0.567-0.696-0.888C1.385,7.89,1.67,5.613,3.155,4.14c0.864-0.856,2.012-1.329,3.233-1.329c1.924,0,3.115,1.12,3.612,1.752c0.499-0.634,1.689-1.752,3.612-1.752c1.221,0,2.369,0.472,3.233,1.329c1.484,1.473,1.771,3.75,0.693,5.537c-0.19,0.32-0.425,0.618-0.695,0.887l-6.562,6.51C10.125,17.229,9.875,17.229,9.719,17.073"></path>
            </svg> <span>{(this.props.favoritesList && this.props.favoritesList[product.id]) ? "Quitar de la lista de deseos" : "Añadir a la lista de deseos"}</span><span></span></button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapState(state) {
    const { alert, product, favorites } = state;
    const { favoritesList } = favorites;
    return { alert, product, favoritesList };
}

const actionCreators = {
    addToCart: cartActions.add,
    getProduct: productActions.get,
    addToFavorites: favoritesActions.add,
};

const connectedProductPage = connect(mapState, actionCreators)(ProductPage)
export default connectedProductPage;