import React, { Component } from 'react';
import { addressesActions } from '../../actions';
import { connect } from 'react-redux';
import './Addresses.css';
import { Link } from "react-router-dom";

class Addresses extends Component {
    constructor(params) {
        super(params);
        this.state = {
            select: this.props.select
        }
        if (this.props.select) {
            this.props.selectAddress(0, 0);
        }
        this.changeSelectedAddress = this.changeSelectedAddress.bind(this);
    }
    changeSelectedAddress(idAddress, realId) {
        this.props.selectAddress(idAddress, realId);
    }
    render() {
        let addressesList = this.props.addressesList;
        return (
            <div id="addresses">
                <h3>{(this.props.select) ? "Selecciona una dirección" : "Direcciones"}</h3>
                { addressesList && addressesList.length > 0 &&/* map on the render */
                    <div className="addressesList">
                        { !this.props.select && addressesList.map((address, index) =>
                        <Link to={"/edit-address/" + index} className="address" key={index}>
                            <p className="name" >{address.name}</p>
                            <p>{address.street}</p>
                            <p>{address.city}{(address.state) ? ", " + address.state + " " : " "}{address.postal_code}</p>
                            <p>{address.country}</p>
                            <p>{address.telephone && "Teléfono: " + address.telephone}</p>
                        </Link>
                        )}
                        { this.props.select && addressesList.map((address, index) =>
                        <div onClick={() => this.changeSelectedAddress(index, address.id_address)} className={(index === this.props.selectedAddress) ? "address selectedAddress" : "address" } key={index}>
                            <p className="name" >{address.name}</p>
                            <p>{address.street}</p>
                            <p>{address.city}{(address.state) ? ", " + address.state + " " : " "}{address.postal_code}</p>
                            <p>{address.country}</p>
                            <p>{address.telephone && "Teléfono: " + address.telephone}</p>
                        </div>
                        )}
                    </div>
                }
                <Link to="/create-address/" className="address addToAddress">
                    <svg className="svg-icon" width="115px" height="115px" viewBox="0 0 20 20">
                        <path d="M14.613,10c0,0.23-0.188,0.419-0.419,0.419H10.42v3.774c0,0.23-0.189,0.42-0.42,0.42s-0.419-0.189-0.419-0.42v-3.774H5.806c-0.23,0-0.419-0.189-0.419-0.419s0.189-0.419,0.419-0.419h3.775V5.806c0-0.23,0.189-0.419,0.419-0.419s0.42,0.189,0.42,0.419v3.775h3.774C14.425,9.581,14.613,9.77,14.613,10 M17.969,10c0,4.401-3.567,7.969-7.969,7.969c-4.402,0-7.969-3.567-7.969-7.969c0-4.402,3.567-7.969,7.969-7.969C14.401,2.031,17.969,5.598,17.969,10 M17.13,10c0-3.932-3.198-7.13-7.13-7.13S2.87,6.068,2.87,10c0,3.933,3.198,7.13,7.13,7.13S17.13,13.933,17.13,10"></path>
                    </svg>
                </Link>
            </div>
        )
    }
}

function mapState(state) {
    const { alert, addresses } = state;
    const { addressesList, selectedAddress } = addresses;
    return { alert, addressesList, selectedAddress };
}

const actionCreators = {
    selectAddress: addressesActions.select
};

const connectedAddresses = connect(mapState, actionCreators)(Addresses)
export default connectedAddresses;
