import config from '../helpers/config.json';
import { authHeader } from '../helpers';

export const productService = {
    list,
    get
};

function list(productUrl, orderParam, order, limit, offset) {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };
    return fetch(`${config.apiUrl}/products?category=${productUrl}&orderBy=${orderParam}&order=${order}&limit=${limit}&offset=${offset}`,
        requestOptions).then(handleResponse);
}

function get(slug) {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };
    return fetch(`${config.apiUrl}/products/${slug}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
