import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { store } from './helpers';
import * as serviceWorker from './serviceWorker';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';

ReactDOM.render(<Provider store={store}><Router><ScrollToTop><App/></ScrollToTop></Router></Provider>, document.getElementById('root'));

serviceWorker.unregister();
